import React from 'react';
import PropTypes from 'prop-types';
import { Container, SeeMoreCard } from './article.css';
import ContentLoader from 'react-content-loader';
import { Link } from 'gatsby';

export const Placeholder = ({ height }) => (
  <ContentLoader
    height={height}
    repeat={3}
    style={{
      borderRadius: 2,
      backgroundColor: '#FFF',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
    }}
  >
    <rect x="20" y="20" rx="4" ry="4" width="230" height="15" />
    <rect x="20" y="40" rx="3" ry="3" width="280" height="15" />

    <rect x="20" y="75" rx="3" ry="3" width="320" height="12" />
    <rect x="20" y="93" rx="3" ry="3" width="340" height="12" />
    <rect x="20" y="110" rx="3" ry="3" width="280" height="12" />
    <rect x="20" y="127" rx="3" ry="3" width="180" height="12" />
  </ContentLoader>
);

Placeholder.propTypes = {
  height: PropTypes.number.isRequired,
};

const SeeMore = () => (
  <Link to="/news">
    <Container>
      <SeeMoreCard>See more...</SeeMoreCard>
    </Container>
  </Link>
);

function Article({ item, seeMore }) {
  if (seeMore) return <SeeMore />;
  return (
    <Container>
      <a
        href={item.guid}
        target="_blank"
        rel="noopener noreferrer"
        className="nostyle"
      >
        <span className="title">{item.title}</span>
        <span className="date">{item.timeAgo}</span>
        <span className="content">{item.contentSnippet}</span>
      </a>
    </Container>
  );
}

Article.propTypes = {
  item: PropTypes.object,
  seeMore: PropTypes.bool,
};

Article.defaultProps = {
  item: null,
  seeMore: false,
};

export default Article;
