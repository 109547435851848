import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './rss-feed.css';
import Article from './article';
import StackGrid from 'react-stack-grid';
import withSize from 'hoc/withSize';
import BREAKPOINTS from 'constants/breakpoints';
import { Placeholder } from './article';
import Header from 'components/header';

function getColumnWidth(screen) {
  if (screen <= BREAKPOINTS.SMALL) return '100%';
  if (screen <= BREAKPOINTS.MEDIUM) return '50%';
  if (screen <= BREAKPOINTS.LARGE) return '33.33%';
  if (screen <= BREAKPOINTS.LARGER) return '25%';
  return '20%';
}

function loaders(n) {
  let arr = [];
  for (let i = 0; i < n; ++i) {
    const rand = Math.floor(Math.random() * 230) + 160;
    arr.push({ height: rand, key: i });
  }
  return arr;
}

const RssFeed = ({
  articles,
  width,
  isFetching,
  colWidth,
  articlesToDisplay,
  seeMore,
}) => (
  <Container>
    <Header>News</Header>
    <StackGrid
      columnWidth={colWidth || getColumnWidth(width)}
      itemComponent="div"
      gutterWidth={10}
      gutterHeight={10}
    >
      {isFetching &&
        loaders(articlesToDisplay).map(el => (
          <Placeholder key={el.key} height={el.height} />
        ))}
      {!isFetching &&
        articles
          .slice(0, articlesToDisplay)
          .map(item => <Article key={item.guid} item={item} />)}
      {!isFetching && seeMore && <Article seeMore />}
    </StackGrid>
  </Container>
);

RssFeed.propTypes = {
  articles: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
  colWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  articlesToDisplay: PropTypes.number,
  seeMore: PropTypes.bool,
};

RssFeed.defaultProps = {
  colWidth: null,
  articlesToDisplay: 20,
  seeMore: false,
};

export default withSize(RssFeed);
