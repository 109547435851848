import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: block;
  padding-bottom: ${props => props.theme.spacing.section};
  h2 {
    font-size: 2em;
    text-align: center;
    padding-bottom: calc(${props => props.theme.spacing.padding} * 2);
    ${MEDIA.MAX_MEDIUM`
      font-size: 1.5em;
    `};
  }
`;
