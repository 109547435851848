import Parser from 'rss-parser';
import { format } from 'timeago.js';

const parser = new Parser();
const CORS_PROXY = 'https://cors-anywhere.herokuapp.com/';

export function mapArticles(res) {
  const getDisplayDate = date =>
    `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;

  return {
    ...res,
    items: res.items.map(el => ({
      ...el,
      displayDate: getDisplayDate(new Date(el.isoDate)),
      timeAgo: format(new Date(el.isoDate)),
    })),
  };
}

function fetchRss(url) {
  return parser.parseURL(CORS_PROXY + url);
}

export default fetchRss;
